//Colors
$backgrond-heroColor: linear-gradient(90deg, #008099 0%, #d62246 100%);
$blue-cards: linear-gradient(0deg, #005566 0%, #00b4d8 100%);
$white: #ffffff;
$turquoise: #008099;
$turquoise-light: #99eeff;
$turquoise-lightes: #ebfcff;
$turquoise-medium: #00b4d8;
$turquoise-natural: #008099;
$dark-turquoise: #00333d;
$pink-natural: #d62246;
$pink-darkest: #b01c39;
$gray: #f3f6f6;
$gray-medium: #AEC2C2;
$color-black: #000000;

//Font Colors
$turquoise-title: #005566;
$darkturquise-text: #00333d;

//Font
$font: "Public Sans", sans-serif;
$normal: 400;
$bold: 700;
$extrabold: 800;
$black: 900;
