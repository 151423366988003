@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.Speaker {
    width: 100%;
    position: relative;
    overflow: hidden;

    &:hover {
        cursor: pointer;
        >img {
            transform: scale(1.2);
        }
        >.speaker-data {
            height: 70%;
            >p {
                display: block;
            }
        }
    }

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .4s ease;
    }

    >.speaker-data {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        background: linear-gradient(rgba(235, 252, 255, 0.02), rgba(2, 106, 126, 0.9));
        display: grid;
        justify-content: flex-start;
        align-items: flex-end;
        transition: all .5s ease;
        padding-left: 10px;
        transition: all .5s ease;
        @include extraLarge {
            height: 40%;
        }

        &:hover {
            >p {
                display: block;
                padding-bottom: 8px;
            }
        }
        > h3 {
            color: $white;
            font-size: 24px;
            left: 25px;
        }
    
        >p {
            color: $white;
            max-width: 280px;
            transition: all .4s ease;
            padding-bottom: 8px;

            @include extraLarge {
                display: none;
            }
        }
    }
}