@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.ListItem {
    width: 100%;
    max-width: 990px;
    margin-block: 20px;

    &.border {
     border-bottom: 1px solid $dark-turquoise;
    }

    >.time {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        color: $dark-turquoise;
        font-size: 14px;
        padding: 4px 10px;
        background-color: $turquoise-lightes;
        width: fit-content;
        border-radius: 5px;

        >.icon  {
            display: inline-block;
            width: 18px;
            height: 18px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.Schedule {
    width: 85%;
    display: grid;
    place-content: center;
    margin: 40px auto;

    > h2 {
        color: $turquoise-title;
        font-size: 32px;
        text-align: center;
        margin-block: 45px;
    }
    >h3 {
        font-size: 28px;
        font-weight: $bold;
        text-align: center;
    }

    >.schedule-wrapper {
        margin-block: 40px;
        position: relative;
        padding-block: 10px;
        padding-inline: 35px;
        box-shadow: 2px 3px 11px 3px  rgba(0,0,0,0.1);
        
        >.full-date {
            color: $white;
            background-color: $pink-natural;
            font-weight: $bold;
            padding: 10px 15px;
            border-radius: 8px 8px 0 0;
            position: absolute;
            transform: rotateZ(270deg);
            top: 10%;
            left: -85px;

            &.dayTwo {
                background-color: $dark-turquoise;
            }
        }
    }
}