@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.CardDetail {
    width: 70%;
    display: grid;
    place-content: center;
    background-color: $white;
    padding: 40px;
    border-radius: 8px;
    transition: all .4s ease;
    margin: auto;

    @include large {
        margin: 0;
        width: 90%;
    }

    >.title-wrapper {

        @include extraLarge {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            >.icon {
                margin-right: 20px;
            }  
        }
        >.icon {
            display: inline-block;
            width: 52px;
            height: 52px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    
        >h3 {
            font-size: 26px;
            color: $dark-turquoise;
            letter-spacing: 2px;
        }
    }

    &:hover {
        color: $white;
        border-bottom: 1px solid $white;

        h3 {
            color: $white;
        }
    }

    > p {
        text-align: justify;
        @include large {
            min-height: 209px;
        }
    }
}