@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.CounterDate {
    background-color: $dark-turquoise;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
    margin: auto;

    @include medium {
        height: 70vh;
    }

    @include large {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 90%;
        height: auto;
        margin: 50px auto;
        border-radius: 50px;
        padding-block: 5px;
    }

    >.counter-content {
        > p {
            font-size: 16px;
            
            @include medium {
                font-size: 24px;
            }

            @include large {
                font-size: 16px;
            }
            
            &:last-of-type {
                margin-block-start: 5px;
                font-size: 28px;
                font-weight: $bold;

                @include medium {
                    font-size: 36px;
                }

                @include large {
                    font-size: 28px;
                }
            }
        }
    }

    >.counter-time {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        gap: 20px;
        
        @include large {
            gap: 10px;
            width: 50%;
        }
        
        >.timer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

           
            &:first-of-type {
                &::after {
                    margin-left: 80px;

                    @include medium {
                        margin-left: 146px;
                    }

                    @include large {
                        margin-left: 130px;
                    }
                }
            }
            

            &::after {
                display: inline-block;
                content: " ";
                border: 1px solid $white;
                background-color: $white;
                height: 1px;
                width: 55px;
                position: absolute;
                margin-left: 95px;
                transform: rotateZ(110deg);

                @include medium {
                    margin-left: 166px;
                }

                @include large {
                    margin-left: 130px;
                    transform: rotateZ(120deg);
                }
            }

            &:last-of-type {
                &::after {
                    border-color: transparent;
                    background-color: transparent;
                }
            }

            >.indicative {
                font-size: 28px;
                letter-spacing: 2px;
            }
        }
    }

    >a.main-btn {
        margin-block: 40px;

        @include medium {
            margin-block: 80px;
        }

        @include large {
            position: absolute;
            margin-block: 60px;
            bottom: 0;
        }
    }
}