@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.Partners {
    width: 100%;
    margin-top: 180px;

   @include wrapper {
       text-align: center;

    > h3 {
        font-size: 36px;
        font-weight: $extrabold;
        color: $turquoise-title;
        margin-bottom: 60px;
    }

    > .partner__brands-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: minmax(100px , 1fr);
        justify-items: center;
        align-content: center;
        align-items: center;

        @include large {
            grid-template-columns: repeat(6, 1fr);
            grid-row: 1fr;
        }

        > .partner__brand {
            width: 60%;
            max-width: 250px;
            margin-bottom: 40px;
            
            @include extraLarge {
                width: 100%;
            }

            > img {
                width: 100%;
                object-fit: contain;
            }

            &:nth-of-type(3) {
                grid-column: 1 / 3;
                grid-row: 2;

                @include large {
                    grid-area: auto;
                }
            }

            &:last-of-type {
                grid-column: 1 / 3;
                grid-row: 4;

                @include large {
                    grid-area: auto;
                }
            }

        }
    }

    > a {
        text-decoration: none;
    }
   }
}