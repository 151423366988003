@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.EventsHero {
    width: 100%;

    @include wrapper {

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 120px 1/2fr 1/2fr;
        justify-items: center;
        max-width: 750px;
        padding-top: 20px;

        @include large {
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: minmax(120px, auto);
            align-items: center;
            justify-items: left;
            gap: 2px;
        }
        > h2 {
            color: $dark-turquoise;
            font-weight: $black;
            font-size: 40px;
        }

        > .EventsHero__content {
        
            > p {
                font-size: 18px;
                color: $darkturquise-text;
            }
        }

        > .EventsHero__image {
            grid-row: 2;
            min-width: 280px;
            width: 90%;
            max-width: 588px;
            @include medium {
                min-width: 400px;
            }

            @include large {
                grid-column: 2;
                grid-row: 1 / 3;
            }
            > img {
                width: 100%;
                height: 280px;

                @include large {
                    height: 422px;
                }
            }
        }
    }
}