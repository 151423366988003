@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.Companies {
    width: 100%;
    text-align: center;

    h2 {
        color: $dark-turquoise;
        margin-bottom: 80px;
    }

    h3 {
        font-size: 24px;
        color: $turquoise-title;
        margin-bottom: 40px;
    }

    hr {
        margin-block: 40px;
    }
    .brands-wrapper {
        width: 90%;
        margin: auto;
        display: grid;
        place-content: center;
        place-items: center;
        gap: 25px;

        @include medium {
            grid-template-columns: repeat(2, 1fr);
        }

        @include large {
            grid-template-columns: repeat(5, 1fr);
            gap: 45px;

            &.universities {
                grid-template-columns: repeat(3, 1fr);
            }
        }
        > figure {
            width: 100%;
            height: 80px;
            max-width: 280px;
            >img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}