@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.Events {
  width: 100%;

   @include wrapper {
    @include large {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 15px;
        box-shadow: 1px 1px 4px 5px rgba(0, 0, 0, 0.03); 
        padding: 30px; 
        border-radius: 40px;
    }

    @include extraLarge {
        padding: 40px; 
        gap: 20px;
    }

    > .Events__title {

        @include large {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
        }

        > h4 {
            font-weight: $extrabold;
            font-size: 16px;
            color: $pink-natural;
            margin-bottom: 0;
        }
    
        > h3 {
            font-weight: $extrabold;
            font-size: 32px;
            line-height: 54px;
            margin: 0;
            letter-spacing: -1.7%;
        }

    }

    > .Event__image {
      max-width: 550px;
      margin-inline: auto;

      @include large {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }

      @include extraLarge {
        min-width: 550px;
        max-width: 720px;
        height: 100%;
      }

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }

    > .Event__content-wrapper {
      text-align: center;
      align-self: baseline;

      @include medium {
        text-align: left;
      }

        > p {
            line-height: 28px;
            text-align: left;
            margin: 0 0 20px 0;
        }
        
        @include large {
            grid-column: 2;
        }

      > .Event__context {
        @include extraLarge {
          display: flex;
          justify-content: space-between;
        }
        > .Event__context-text {
          min-height: 40px;
          display: flex;
          align-items: center;
          > .icon-event {
            display: inline-block;
            width: 40px;
            height: 40px;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 8px;
          }

          > .calendar-icon {
            background-image: url(https://cdn.sonarescrecer.org/images/calendar.png);
          }

          > .clock-icon {
            background-image: url(https://cdn.sonarescrecer.org/images/clock.png);
          }

          > .type-event {
            background-image: url(https://cdn.sonarescrecer.org/images/events.png);
          }
        }
      }

      > a {
        align-items: center;
        display: flex;
        height: 36px;
        justify-content: center;
        margin-top: 22px;
        padding-block: 0;
        text-decoration: none;
        width: 100%;

        @include small {
          max-width: 180px;
        }
      }
    }
  }
}
