@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.ConferenceSummary {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 80vh;
    height: auto;
    padding-block-end: 80px;


    >.description {
        margin: auto;
        padding-block-start: 50px;
        width: 100%;
        max-width: 750px;
        > h2 {
            font-size: 32px;
            color: $white;
            text-align: center;
        }
    
        > p {
            color: $white;
            text-align: center;
        }
    }

    >.cards-wrapper {
        width: 90%;
        max-width: 1200px;
        display: grid;
        place-content: center;
        margin: 80px auto;
        gap: 25px;

        @include large {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: auto;
            gap: 40px;
        }

    }

}