@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.Speakers {
    width: 100%;
    margin-block: 60px;


    >h2 {
        color: $turquoise-title;
        font-size: 36px;
        font-weight: $extrabold;
        text-align: center;
    }

    >.speakers-wrapper {
        >.Speaker.hidden {
            display: none;
        }
        @include medium {
            width: 100%;
            place-content: center;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(auto, 426px));
            grid-template-rows: repeat(2, 1fr);
        }

        @include large {
            >.Speaker:last-of-type {
                display: block;
            }
        }

        @include extraLarge {
            background: linear-gradient($turquoise-lightes, $turquoise);
            grid-template-columns: repeat(7, 1fr);

        }
    }
}