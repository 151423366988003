@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700;800;900&display=swap');
@import './assets/styles/Mixin.scss';
@import './assets/styles/Variables.scss';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Public Sans', sans-serif;
  
  figure {
    margin: 0;
  }

  section {
    margin-bottom: 160px;
  }

  .btn-primary {
    width: 90%;
    max-width: 340px;
    display: inline-block;
    border-radius: 4px;
    background-color: $pink-natural;
    color: $white;
    font-weight: $bold;
    text-align: center;
    padding-block: 10px;
  }

  .main-btn {
    width: 90%;
    max-width: 340px;
    display: inline-block;
    border-radius: 4px;
    background-color: $white;
    color: $dark-turquoise;
    font-weight: $bold;
    text-align: center;
    padding-block: 10px;
    text-decoration: none;
    font-size: 18px;

    @include large {
      margin: 10px auto;
      font-size: 24px;
    }
  }

  .cta-btn {
    width: 100%;
    padding: 10px;
    max-width: 340px;
    display: inline-block;
    border-radius: 4px;
    background-color: $dark-turquoise;
    color: $white;
    font-weight: $bold;
    text-align: center;
    text-decoration: none;
    font-size: 18px;

    &:hover {
      opacity: 0.8;
    }

    @include large {
      
      margin: 10px auto;
      font-size: 24px;
    }
  }

  .register-btn {
    width: 90%;
    max-width: 340px;
    display: inline-block;
    border-radius: 4px;
    background-color: $white;
    color: $dark-turquoise;
    font-weight: $bold;
    text-align: center;
    padding: 10px 20px;
    text-decoration: none;

    @include large {
      background: none;
      font-weight: $normal;
    }
  }

  .btn-secundary {
    width: 90%;
    max-width: 140px;
    display: inline-block;
    border-radius: 4px;
    background-color: $white;
    color: $pink-natural;
    font-weight: $bold;
    text-align: center;
    padding-block: 6px;
  }

  p {
    font-size: 18px;
  }

  i.fa {
    margin-inline-end: 16px;
    font-size: 1.15em;
    color: $white;
  }

  .youtube {
    background-color: #bb0000;
    color: $white;
    margin: 25px auto;

    &:hover {
      opacity: 0.8;
    }
  }

  .facebook {
    background-color: #3B5998;
    color: $white;
    margin: 25px auto;

    &:hover {
      opacity: 0.8;
    }
  }

  .linkedin {
    background-color: #007bb5;
    color: white;

    margin: 25px auto;

    &:hover {
      opacity: 0.8;
    }
  }
}
