@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.bio-header {
    width: 100%;
    padding-block: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 40px;

    @include large {
        flex-direction: column;
    }

    >h1 {
        color: $white;
        text-align: center;
        font-size: 36px;
        letter-spacing: 8px;
    }

    >.navigation {
        width: 90%;
        display: flex;
        margin: auto;
        justify-content: space-between;
        align-items: center;
        
        >.goback-btn {
            border: none;
            background: none;
            color: $white;

            @include large {
                font-size: 24px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        >p {
            color: $white;
            display: none;

            @include large {
                display: block;
            }
        }

       >a {
        >.Header-logo {
            width: 55px;
            @include large {
                width: 250px;
            }

            >picture{
                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
       }
    }
}

.SpeakerBiography {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    @include large {
        width: 90%;
        margin: 40px auto;
        flex-direction: row;
    }

    > figure {
        width: 90%;
        max-width: 530px;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
        }
    }

    >.speaker-bio {
        width: 90%;
        max-width: 550px;
        background-color: $turquoise-lightes;
        padding: 15px;

        > h2 {
            color: $dark-turquoise;
        }

        >p {
            line-height: 24px;
        }
    }
}

@keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInLeft {
    animation: fadeInLeft 1.4s ease-in-out;
  }

  
  .fadeInRight {
    animation: fadeInRight 1.4s ease-in-out;
  }