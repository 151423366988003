@import "../../../assets/styles/Variables.scss";

.Loading {
  font-size: 34px;
  color: $turquoise;
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-content: center;
  font-weight: bold;
}
