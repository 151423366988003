@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.EventHero {
    width: 100%;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    place-content: center;
    position: relative;

    @include large {
        padding-block-start: 126px;
    }

    >.Event-content {
        width: 90%;
        max-width: 1200px;
        margin: auto;

        >h1 {
            color: $white;
            font-size: 32px;
            line-height: 52px;
            text-align: center;

            @include extraLarge {
                font-size: 42px;
                line-height: 85px;
            }

            @include extraExtraLarge {
                font-size: 72px;
            }
        }

        > h2 {
            color: $white;
            font-size: 28px;
            text-align: center;
            line-height: 52px;
            letter-spacing: 2px;

            @include extraLarge {
                font-size: 32px;
                line-height: 85px;
            }

            @include extraExtraLarge {
                font-size: 52px;
            }

        }

        > p {
            color: $white;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 2px;

            &.live-traduction, &.join {
                font-size: 28px;
            }
        }
    }

    >a.main-btn {
        &.join {
            display: block;
            text-align: center;
            margin: 40px auto;
        }
    }
}