@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.Sponsors {
    width: 100%;
    margin-top: 240px;

    @include wrapper {
        > h3 {
            font-size: 36px;
            font-weight: $extrabold;
            color: $turquoise-title;
            margin-bottom: 60px;
            text-align: center;
        }

        > .sponsors-section {
            display: grid;
            place-content: center;

            @include large {
                grid-template-columns: repeat(2, 30%);
                grid-auto-rows: auto;
            }

            > .brand__supporter {
                width: 90%;
                max-width: 200px;
                margin-block: 40px;

                @include large {
                    max-width: 150px;
                    place-self: center;
                }

                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}